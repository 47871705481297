












































import { Component, Vue } from "vue-property-decorator";
// @ is an alias to /src
const Swiper = () => import("@/components/swiper.vue");

@Component({
  components: {
    Swiper
  }
})
export default class MainVisual extends Vue {}
