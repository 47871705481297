import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/tailwind.css";
import "@/assets/css/common.scss";
import "@/assets/css/variable.scss";
import VueLazyload from "vue-lazyload";
import VueAnalytics from "vue-analytics";

import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);
Vue.config.productionTip = false;

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  filter: {
    webp(listener: any, options: any) {
      if (!options.supportWebp) return;
      listener.src = listener.src.replace(/png|jpg$/, "webp");
    }
  }
});
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

Vue.use(VueAnalytics, {
  id: "UA-162466429-1",
  router
});
