var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{ref:"splash",class:['splash', 'fixed', 'w-screen', 'h-screen', 'active']}),_c('main-visual'),_vm._l((_vm.options),function(option,index){return _c('block-template',{key:index,attrs:{"option":option}})}),_c('works-info',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.visibilityChanged,
      intersection: {
        rootMargin: '-35% 0px -35% 0px',
      },
    }),expression:"{\n      callback: visibilityChanged,\n      intersection: {\n        rootMargin: '-35% 0px -35% 0px',\n      },\n    }"}],staticClass:"animation"}),_c('works-new',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.visibilityChanged,
      intersection: {
        rootMargin: '-35% 0px -35% 0px',
      },
    }),expression:"{\n      callback: visibilityChanged,\n      intersection: {\n        rootMargin: '-35% 0px -35% 0px',\n      },\n    }"}],staticClass:"animation"}),_c('footer-block')],2)}
var staticRenderFns = []

export { render, staticRenderFns }