




































import { Component, Vue } from "vue-property-decorator";
// @ is an alias to /src
import MainVisual from "@/components/MainVisual.vue";
import VueHead from "vue-head";
const BlockTemplate = () => import("@/components/BlockTemplate.vue");
const WorksInfo = () => import("@/components/WorksInfo.vue");
const WorksNew = () => import("@/components/WorksNew.vue");
const FooterBlock = () => import("@/components/FooterBlock.vue");

Vue.use(VueHead);
@Component<Home>({
  components: {
    MainVisual,
    BlockTemplate,
    WorksInfo,
    WorksNew,
    FooterBlock,
  },
  head: {
    title: {
      inner: "もく保育園",
      separator: "|",
      complement: "つくる子は育つ。",
    },
    meta: [
      {
        name: "description",
        content:
          "じぶんの手でつくったものでお金を得る。その体験は、何よりもお金の勉強になる。",
      },
      { property: "og:title", content: "もく保育園｜つくる子は育つ。" },
      {
        property: "og:description",
        content:
          "じぶんの手でつくったものでお金を得る。その体験は、何よりもお金の勉強になる。",
      },
      { property: "og:type", content: "website" },
      // { property: 'og:url', content: 'https://example.com' },
      {
        property: "og:image",
        content: process.env.VUE_APP_API_BASE_URL + "ogp.jpg",
      },
      { name: "twitter:card", content: "summary_large_image" },
      // { name: 'robots', content: 'noindex' }
    ],
  },
})
export default class Home extends Vue {
  $refs!: {
    splash: any;
  };
  visibilityChanged(isVisible: boolean, entry: any) {
    if (isVisible) {
      entry.target.classList.add("fadeIn");
    } else {
      // entry.target.classList.remove('fadeIn')
    }
  }
  options = [
    {
      id: "artist",
      mainImage: ["/images/image_1.png", "/images/pc/image_1.png"],
      titleText: [
        "/images/text/text_1.png",
        "/images/pc/text/text_1.png",
        "もく保育園の園児はアーティストです。",
      ],
      descriptionText: [
        "ピカソが園児のときにつくった作品が<br/>あったとしたら、今どんな価値があるでしょうか？<br/>もくこども美術館で展示しています。生で観てご購入ください。園児たちの未来の可能性を買いませんか。",
        "ピカソが園児のときにつくった<br/>作品があったとしたら、<br/>今どんな価値があるでしょうか？<br/>もくこども美術館で展示しています。<br/>生で観てご購入ください。<br/>園児たちの未来の可能性を買いませんか。",
      ],
      btn: ["/images/btn_2.png", "/images/pc/btn_2.png"],
    },
    {
      id: "photographer",
      mainImage: ["/images/image_2.png", "/images/pc/image_2.png"],
      titleText: [
        "/images/text/text_3.png",
        "/images/pc/text/text_3.png",
        "もく保育園の園児は、カメラマンです。",
      ],
      descriptionText: [
        "目線の低い写真が多めです。<br/>写真は自由にダウンロードしてお使いください。<br/>もくこども美術館では写真の展示もしています。",
        "目線の低い写真が多めです。<br/>写真は自由にダウンロードしてお使いください。<br/>もくこども美術館では写真の展示もしています。",
      ],
      btn: ["/images/btn_3.png", "/images/pc/btn_3.png"],
      link:
        "https://mokupreschool.wixsite.com/mokuhoikuen-art/%E3%82%AE%E3%83%A3%E3%83%A9%E3%83%AA%E3%83%BC",
    },
    {
      id: "creater",
      mainImage: ["/images/image_3.png", "/images/pc/image_3.png"],
      titleText: [
        "/images/text/text_5.png",
        "/images/pc/text/text_5.png",
        "もく保育園の園児は、カメラマンです。",
      ],
      descriptionText: [
        "キャッチコピーとデザインをがんばって考えます。<br/>広告主の方の伝えたいことよりも、思ったことを伝えてしまいがちです。<br/>広告主募集中です。",
        "キャッチコピーとデザインを<br/>がんばって考えます。<br/>広告主の方の伝えたいことよりも、<br/>思ったことを伝えてしまいがちです。<br/>広告主募集中です。",
      ],
      btn: ["/images/btn_4.png", "/images/pc/btn_4.png"],
    },
  ];
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("load", () => {
        this.$refs.splash.classList.remove("active");
      });
    });
  }
}
