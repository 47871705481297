var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mv"},[_c('div',{staticClass:"lg:hidden"},[_vm._m(0),_c('div',{staticClass:"mv--text"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/text/mv_txt.png'),expression:"'/images/text/mv_txt.png'"}],attrs:{"alt":""}})]),_c('Swiper',{attrs:{"slides":[
        '/images/top_sp_1.jpg',
        '/images/top_sp_2.jpg',
        '/images/top_sp_3.jpg',
        '/images/top_sp_4.jpg',
        '/images/top_sp_5.jpg'
      ]}})],1),_c('div',{staticClass:"lg:block hidden"},[_c('Swiper',{attrs:{"slides":[
        '/images/pc/top_1.jpg',
        '/images/pc/top_2.jpg',
        '/images/pc/top_3.jpg',
        '/images/pc/top_4.jpg',
        '/images/pc/top_5.jpg'
      ]}}),_c('div',{staticClass:"left_menu absolute top-0 left-0 z-10"},[_vm._m(1),_c('a',{attrs:{"href":"https://www.instagram.com/mokuhoikuen_art/?hl=ja","target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/pc/link_left.png'),expression:"'/images/pc/link_left.png'"}],attrs:{"alt":""}})])])],1),_c('div',{staticClass:"mv_text absolute z-10 hidden lg:block"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('/images/pc/text/text_mv.png'),expression:"'/images/pc/text/text_mv.png'"}],attrs:{"alt":""}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo relative"},[_c('img',{attrs:{"src":"/images/logo.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":"/images/pc/logo_left.png","alt":""}})])}]

export { render, staticRenderFns }